import React from 'react'

export const HtmlIcon = () => {
  return (
    <svg width="40" height="47" xmlns="http://www.w3.org/2000/svg">
      <title>HTML 5</title>
      <g fill="none" fillRule="evenodd">
        <g id="html5-brands" fill="currentColor" fillRule="nonzero">
          <path d="M0 0l3.635 41.23 16.313 5.437 16.417-5.438L40 0H0zm32.104 13.323H12.958l.427 5.146h18.292L30.26 33.927 20.062 36.74v.03h-.114L9.667 33.928l-.625-7.896h4.968L14.375 30l5.573 1.51L25.542 30l.625-6.48H8.78L7.448 8.355h25.114l-.458 4.969z" />
        </g>
      </g>
    </svg>
  )
}
