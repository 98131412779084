import React from 'react'

function ProjectOverview(props) {
  const services = props.services.map(item => (
    <li className="project-overview__services-item" key={item.id}>
      <p
        className="project-overview__services-paragraph
                    text--6"
      >
        {item.text}
      </p>
    </li>
  ))

  const technologies = props.technologies.map(tech => (
    <li className="project-overview__technologies-item" key={tech.id}>
      <span className="project-overview__technologies-icon">{tech.icon}</span>
    </li>
  ))

  return (
    <section className="project-overview">
      <div className="container">
        <div className="project-overview__inner">
          {props.category && <p className="hero-section__label shared-styles__section-heading">{props.category}</p>}
          <h1 className="project-overview__heading text--3">{props.title}</h1>
          <div className="project-overview__details">
            <a
              href={props.link}
              className="project-overview__see-online
                          btn
                          btn--yellow
                          btn--secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Site
            </a>
            <p className="project-overview__services text--6">Services</p>
            <ul className="project-overview__services-list">{services}</ul>
            <ul className="project-overview__technologies-list">
              {technologies}
            </ul>
          </div>
          <div className="project-overview__description">
            <h2 className="project-overview__subheading text--6">
              {props.subtitle}
            </h2>
            <p className="project-overview__paragraph text--5">
              {props.paragraph1}
            </p>

            {props.paragraph2 ? (
              <p className="project-overview__paragraph text--5">
                {props.paragraph2}
              </p>
            ) : null}

            {props.paragraph3 ? (
              <p className="project-overview__paragraph text--5">
                {props.paragraph3}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectOverview
