import React from 'react'

function HeaderImg({ header, icon, img, wrapper }) {
  return (
    <section className={'header-img ' + (header ? header : '')}>
      <div className={'header-img__img-wrapper ' + (wrapper ? wrapper : '')}>
        {img ? <img src={img} alt="Img" className="header-img__img" /> : null}

        {icon ? <span className="header-img__icon">{icon}</span> : null}
      </div>
    </section>
  )
}

export default HeaderImg
