import React from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import TextBlock from '../../components/TextBlock/TextBlock'
import BottomNav from '../../components/BottomNav/BottomNav'
import TestimonialVideo from '../../components/TestimonialVideo/TestimonialVideo'
import Logo from '../../images/vooba-abnix-logo.webp'
import { JavascriptIcon } from '../../components/icons/JavascriptIcon'
import { TypescriptIcon } from '../../components/icons/TypescriptIcon'
import { AngularIcon } from '../../components/icons/AngularIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'

// import Testimonial from '../../components/Testimonial/Testimonial'
// import Slider from '../../components/Slider/Slider'
// import Goals from '../../components/Goals/Goals'
// import Outcomes from '../../components/Outcomes/Outcomes'

const AbnixPage = () => {
  const services = [
    {
      id: 1,
      text: 'Providing development resources',
    },
    {
      id: 2,
      text: 'Project management',
    },
    {
      id: 3,
      text: '6 people team',
    },
  ]

  // const goals = [
  //   {
  //     id: 11,
  //     text:
  //       'Our goal was to help the client develop a modern and scalable marketplace.',
  //   },
  //   {
  //     id: 22,
  //     text:
  //       'Then we needed to integrate the marketplace with the client’s Salesforce CRM account, Stripe for collecting payments, and set up automated email notifications.',
  //   },
  //   {
  //     id: 33,
  //     text:
  //       'We also needed to develop a dedicated admin panel so the client can manage the content themselves.',
  //   },
  // ]

  // const outcomes = [
  //   {
  //     id: 111,
  //     title: '24',
  //     subtitle: 'months of ongoing partnership',
  //   },
  //   {
  //     id: 222,
  //     title: '700+',
  //     subtitle: 'active platform users',
  //   },
  //   {
  //     id: 333,
  //     title: '3',
  //     subtitle: 'platforms integrated with the client’s new platform (X,Y,Z)',
  //   },
  //   {
  //     id: 444,
  //     title: '7',
  //     subtitle: 'processes automated',
  //   },
  //   {
  //     id: 555,
  //     title: '',
  //     subtitle:
  //       'a dedicated admin panel where client can manage the content themselves',
  //   },
  // ]

  // const slides = [
  //   {
  //     id: 1,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-4.jpg',
  //     alt: 'Emersoft Team with Brad Lea in LighSpeed VT office',
  //   },
  //   {
  //     id: 2,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-2.jpg',
  //     alt: 'Emersoft Team in LightSpeed VT office',
  //   },
  //   {
  //     id: 3,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-3.jpg',
  //     alt: 'Emersoft Team visiting client in Las Vegas',
  //   },
  //   {
  //     id: 4,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-1.jpg',
  //     alt: 'LighSpeed VT office',
  //   },
  // ]

  const techs = [
    {
      id: 1111,
      icon: JavascriptIcon(),
    },
    {
      id: 6666,
      icon: TypescriptIcon(),
    },
    {
      id: 2222,
      icon: AngularIcon(),
    },
    {
      id: 4444,
      icon: HtmlIcon(),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Abnix"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
          `Abnix`,
          `Angular`,
        ]}
      />
      <HeaderImg
        header="header-img--abnix"
        img={Logo}
        wrapper="header-img__img-wrapper--abnix"
      />
      <ProjectOverview
        title="Abnix"
        link="https://www.abnixsolutions.com/c/our-product"
        services={services}
        technologies={techs}
        subtitle=""
        paragraph1="Abnix Solutions provides a professional website package specifically for the tool and plant hire industry"
        paragraph2="Built with a user friendly administration area for you to maintain your website with few IT skills"
        paragraph3="Designed to provide small to medium sized hire companies with a website that will compete with national companies"
      />
      <TestimonialVideo
        url="https://www.youtube.com/watch?v=lOdhU3ZMVT8"
        author="Tony Humberstone"
        authorRole="Managing Director"
        thumbnail="https://cdn.emersoft.co/emersoft-website/vooba/tony-humberstone-emersoft-testimonial-vooba.jpg"
        alt="Tony Humberstone"
      />
      <TextBlock
        heading="Brief"
        paragraph1="When the client came to us, they already had a legacy system for The Tool & Plant Hire Website Packages."
      />
      {/* <Slider slides={slides} /> */}
      {/* <Goals goalsList={goals} /> */}
      <TextBlock
        heading="Our Process"
        paragraph1="We first mapped out and agreed on all business deliverables as the client wasn’t tech-savvy."
        paragraph2="Then we proceeded to work very closely with their chief brand architect to provide us with suggestions for the platform design."
      />
      {/* <Outcomes outcomes={outcomes} /> */}
      <BottomNav
        heading="See More"
        firstLink="/our-work/vooba"
        firstHeading="Vooba"
        secondLink="/our-work/radium"
        secondHeading="Radium"
      />
    </Layout>
  )
}

export default AbnixPage
