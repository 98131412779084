import React from 'react'

export const AngularIcon = () => {
  return (
    <svg width="40" height="43" xmlns="http://www.w3.org/2000/svg">
      <title>Angular</title>
      <g fillRule="nonzero" fill="none" opacity=".7">
        <path
          fill="#585858"
          d="M.016 7.035L19.74.007 39.996 6.91l-3.28 26.096-16.976 9.402-16.71-9.277z"
        />
        <path
          fill="#4B4B4B"
          d="M39.996 6.91L19.74.007v42.401l16.976-9.386z"
        />
        <path
          d="M19.771 4.958L7.481 32.304l4.591-.078 2.468-6.17h11.026l2.701 6.248 4.389.078L19.77 4.958zm.032 8.761l4.154 8.683h-7.809l3.655-8.683z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
